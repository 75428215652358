<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">系统监管</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()" class="cur-a">自检异常数据</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start;">
          <div class="searchbox" style="margin-bottom: 15px;">
            <div title="班级搜索" style="display: flex; align-items: center" class="searchboxItem ci-full">
              <span class="itemLabel">班级搜索:</span>
              <el-select v-model="projectId" placeholder="请选择" remote size="small" clearable :remote-method="superProjectSelect" @visible-change="clearProjectSearchModel">
                <template>
                  <div class="select-header">
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 150px;
                      ">班级编号</span>
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 250px;
                      ">班级名称</span>
                  </div>
                  <div class="select-header">
                    <el-input v-model="seaProjectCode" v-on:input="superProjectSelect" type="text" size="small" placeholder="班级编号" clearable style="width: 150px" />
                    <el-input v-model="seaProjectName" v-on:input="superProjectSelect" type="text" size="small" placeholder="请输入班级名称" clearable style="margin-left: 50px; width: 250px" />
                  </div>
                </template>
                <el-option v-for="item in projectList" :key="item.projectId" :label="item.projectName" :value="item.projectId" style="display: flex;justify-content:space-between;align-items: center;overflow:hidden;word-wrap:break-word;word-break:break-all;white-space: pre-wrap;height:auto">
                  <span style="width: 180px; font-size: 13px;">{{
                    item.projectCode
                  }}</span>
                  <span style="width: 250px;font-size: 13px;">{{ item.projectName }}</span>
                </el-option>
              </el-select>
            </div>
            <div title="学员搜索" style="display: flex; align-items: center" class="searchboxItem ci-full">
              <span class="itemLabel" style="
                  min-width: 5rem;
                  text-align: right;
                  font-size: 14px;
                  padding: 0 0.5rem;
                ">学员搜索:</span>
              <el-select v-model="userId" placeholder="请选择" no-data-text="没有数据" remote size="small" clearable :remote-method="superUserSelect" @visible-change="clearUserSearchModel">
                <template>
                  <div class="select-header">
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 100px;
                      ">学员姓名</span>
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 140px;
                      ">身份证号码</span>
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 100px;
                      ">学员电话</span>
                  </div>
                  <div class="select-header">
                    <el-input v-model="seaUserName" v-on:input="superUserSelect" type="text" size="small" placeholder="学员姓名" clearable style="width: 100px" />
                    <el-input v-model="seaUserIdcard" v-on:input="superUserSelect" type="text" size="small" placeholder="身份证号码" clearable style="margin-left: 50px; width: 140px" />
                    <el-input v-model="seaUserMobile" v-on:input="superUserSelect" type="text" size="small" placeholder="学员电话" clearable style="margin-left: 50px; width: 100px" />
                  </div>
                </template>
                <el-option v-for="item in userList" :key="item.userId" :label="item.userName" :value="item.userId">
                  <span style="width: 100px; font-size: 13px">{{
                    item.userName
                  }}</span>
                  <span style="width: 140px; margin-left: 50px; font-size: 13px">{{ item.idcard }}</span>
                  <span style="width: 100px; margin-left: 50px; font-size: 13px">{{ item.mobile }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select size="small" v-model="compId" remote :remote-method="getCompanyList" filterable clearable placeholder="请至少输入两个字搜索" style="width: 100%">
                <el-option v-for="item in CompanyList" :key="item.compId" :label="item.compName" :value="item.compId"></el-option>
              </el-select>
            </div>
            <div title="检查日期" class="searchboxItem ci-full">
              <span class="itemLabel">检查日期:</span>
              <el-date-picker v-model="dateList" size="small" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value="yyyy-MM-dd" value-format="yyyy-MM-dd" />
            </div>
            <div class="df" style="margin-left:10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="exportData">导出</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table class="className" ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="学员姓名" align="left" show-overflow-tooltip prop="userName" min-width="80" />
              <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idcard" min-width="150" />
              <el-table-column label="手机号" align="left" show-overflow-tooltip prop="mobile" min-width="100" />
              <el-table-column label="班级编码" align="left" show-overflow-tooltip prop="projectCode" min-width="180" />
              <el-table-column label="班级名称" align="left" show-overflow-tooltip prop="projectName" min-width="180" />
              <el-table-column label="机构名称" align="left" show-overflow-tooltip prop="compName" min-width="180" />
              <el-table-column label="培训有效期" align="left" show-overflow-tooltip min-width="180">
                <template slot-scope="{row}">
                  {{row.startDate}} ~ {{row.endDate}}
                </template>
              </el-table-column>
              <el-table-column label="课件名称" align="left" show-overflow-tooltip prop="kpointName" min-width="180" />
              <el-table-column label="学习ID" align="left" show-overflow-tooltip prop="studyId" min-width="100" />
              <el-table-column label="检查日期" align="left" show-overflow-tooltip prop="studyCheckDate" min-width="80" />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import { mapGetters } from "vuex";
import List from "@/mixins/List";
export default {
  name: "agenciesGatheringStatistics",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data () {
    return {
      compId: "",
      CompanyList: [],
      dateList: [],
      // 班级搜索
      projectId: "",
      seaProjectCode: "",
      seaProjectName: "",
      projectList: [{}],
      // 班级搜索
      // 学员搜索
      userId: "",
      seaUserName: "",
      seaUserIdcard: "",
      seaUserMobile: "",
      userList: [{}],
      // 学员搜索 end
    };
  },
  created () {
    this.superProjectSelect();
    this.superUserSelect();
  },
  watch: {
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  mounted () { },
  methods: {
    getData (pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.projectId) {
        params.projectId = this.projectId;
      }
      if (this.userId) {
        params.userId = this.userId;
      }
      if (this.dateList?.length === 2) {
        params.studyCheckDateStart = this.dateList[0];
        params.studyCheckDateEnd = this.dateList[1];
      }
      this.doFetch({
        url: "/biz/projectUser/checkAbnormal",
        params,
        pageNum,
      });
    },
    // 导出
    exportData () {
      const params = {};
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.projectId) {
        params.projectId = this.projectId;
      }
      if (this.userId) {
        params.userId = this.userId;
      }
      if (this.dateList?.length === 2) {
        params.studyCheckDateStart = this.dateList[0];
        params.studyCheckDateEnd = this.dateList[1];
      }
      this.$post("/biz/projectUser/checkAbnormalExport", params).then((res) => {
        const arr = res.data;
        for (let item of arr) {
          if (!this.downloadItems.includes(item.taskId)) {
            console.log(item.taskId);
            this.$store.dispatch("pushDownloadItems", item.taskId);
          } else {
            this.$message.warning(
              "[" + item.fileName + "]已经申请下载,请耐心等待"
            );
          }
        }
      });
    },
     // 机构搜索
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 学员搜索
    superUserSelect (e) {
      this.$post(
        "/biz/user/superUserSelect",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.seaUserName,
          idcard: this.seaUserIdcard,
          mobile: this.seaUserMobile,
          projectId: this.projectId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.userList = [{}];
        } else {
          this.userList = res.data.list;
        }
      });
    },
    clearUserSearchModel (e) {
      if (e) {
        this.seaUserName = "";
        this.seaUserIdcard = "";
        this.seaUserMobile = "";
        this.superUserSelect();
      }
    },
    // 学员搜索
    // 班级搜索
    superProjectSelect (e) {
      this.$post(
        "/biz/project/superSelectProject",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          projectName: this.seaProjectName,
          projectCode: this.seaProjectCode,
          userId: this.userId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.projectList = [{}];
        } else {
          this.projectList = res.data.list;
        }
      });
    },
    clearProjectSearchModel (e) {
      if (e) {
        this.seaProjectName = "";
        this.seaProjectCode = "";
        this.superProjectSelect();
      }
    },
    // 班级搜索

  },
};
</script>
<style lang="less" scoped>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.statistics {
  margin-bottom: 10px;
  > span + span {
    margin-left: 10px;
  }
}
.className :empty:not(th):not(tbody)::before {
  content: '--';
  color: #606266;
}
/deep/ .pre-wrap .cell {
  white-space: pre-wrap;
}
</style>
